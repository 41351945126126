import { createComponentUtils, createRootContext } from "../../__utils/atlas";
import type { AtlasSelectContentItem, AtlasSelectState } from "../types";

// config
// ------

const COMPONENT_NAME = "Select";

export const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// context
// -------

export type SelectRootContext = {
  select: AtlasSelectState;
  isCustomTrigger: boolean;
  setIsCustomTrigger: (value: boolean) => void;
};

export const { RootContext, RootProvider, useRootContext } =
  createRootContext<SelectRootContext>(COMPONENT_NAME);

// list props
// ----------

export type BaseListProps = {
  items: AtlasSelectContentItem[];
  isVirtual?: boolean;
};

// is option selected
// ------------------

export function isOptionSelected(
  stateValue?: string | string[],
  itemValue?: string
) {
  if (stateValue == null || itemValue == null) return false;
  if (Array.isArray(stateValue)) {
    return stateValue.includes(itemValue);
  }
  return stateValue === itemValue;
}
