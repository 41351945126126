import {
  Select,
  SelectItem,
  SelectItemProps,
  SelectPopover,
  SelectSeparator,
  SelectState,
} from "ariakit";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  scrollWorkaroundCompositeProps,
  useCompositeScrollWorkaround,
} from "../__utils/__deprecated";
import { usePExtendedState } from "../__utils/atlas";
import {
  CollectionItemRenderers,
  usePCollection,
} from "../__utils/collections";
import {
  CompositeListRenderer,
  GetCustomPinnedIndexes,
} from "../__utils/CompositeListRenderer";
import {
  createDefaultProps,
  mergePropsIntoSingleChild,
} from "../__utils/react";
import { FloatingCard } from "../floating-card";
import { OptionSeparator } from "../option";
import { AtlasOptionItemProps } from "../option/types";
import { SelectTrigger } from "./SelectTrigger";
import type {
  AtlasSelectContentComponent,
  AtlasSelectContentItem,
  AtlasSelectContentProps,
  AtlasSelectOptionComponent,
  AtlasSelectRootComponent,
  AtlasSelectSeparatorComponent,
  AtlasSelectTriggerComponent,
} from "./types";
import { useSelectState } from "./use-select-state";
import { getOptionProps } from "./utils/option";
import { SelectLabel } from "./utils/SelectLabel";
import {
  BaseListProps,
  createComponent,
  el,
  isOptionSelected,
  RootProvider,
  useRootContext,
} from "./utils/shared";
import { useSearchableSelectContent } from "./utils/use-searchable-select-content";

// root
// ----

/** The root of the select component. Expects the trigger and the content.
 *
 * @example
 * <Select.Root>
 *   <Select.Trigger />
 *   <Select.Content items={items} />
 * </Select.Root>
 */
export const Root = createComponent<AtlasSelectRootComponent>(
  ({ children, state, ...stateProps }) => {
    const select = useSelectState(stateProps);

    const [isCustomTrigger, setIsCustomTrigger] = useState(false);

    const contextValue = useMemo(
      () => ({
        select: state ?? select,
        isCustomTrigger,
        setIsCustomTrigger,
      }),
      [state, select, isCustomTrigger]
    );

    return <RootProvider value={contextValue}>{children}</RootProvider>;
  },
  { forwardRef: false, treeName: "Root" }
);

// trigger
// -------

/** The trigger of a select. Must be a child of `<Select.Root />`. `Button`
 * can optionally be used as the trigger, by passing it as a child.
 *
 * @example
 * <Select.Trigger />
 *
 * // or with a button as trigger
 * <Select.Trigger>
 *   <Button />
 * </Select.Trigger>
 */
export const Trigger = createComponent<AtlasSelectTriggerComponent>(
  ({ children, ...p0 }) => {
    const {
      select: originalSelect,
      isCustomTrigger,
      setIsCustomTrigger,
    } = useRootContext();

    const [select, props] = usePExtendedState(p0, originalSelect);

    const { current: labelCache } = useRef<Record<string, string>>({});

    // this hack is necessary because virtual items dissapear from the item list when the
    // list is scrolled - we might be able to remove it once we migrate to Ariakit's
    // built-in virtualization
    const labels = useMemo(() => {
      const alreadySeenValues: string[] = [];
      const result: string[] = [];
      if (Array.isArray(select.value)) {
        select.value.forEach((value) => {
          if (alreadySeenValues.includes(value)) return;
          const valueItem = select.items.find((item) => item.value === value);
          if (valueItem) {
            // @ts-expect-error - The Item type doesn't know about getItem.
            const { label } = valueItem;
            result.push(label);
            alreadySeenValues.push(value);
            labelCache[value] = label;
          } else {
            const cacheEntry = labelCache[value];
            if (cacheEntry) {
              alreadySeenValues.push(value);
              result.push(cacheEntry);
            }
          }
        });
      } else {
        const item = select.items.find(({ value }) => value === select.value);
        if (item) {
          // @ts-expect-error - The Item type doesn't know about getItem.
          const { label } = item;
          result.push(label);
          labelCache[select.value] = label;
        } else {
          const cacheEntry = labelCache[select.value];
          if (cacheEntry) result.push(cacheEntry);
        }
      }
      return result;
    }, [labelCache, select.items, select.value]);

    const labelChildren =
      labels.length > 1 ? (
        <SelectLabel items={labels} />
      ) : (
        labels[0] ?? "No selection"
      );

    useEffect(() => {
      const currentValue = Boolean(children);
      if (isCustomTrigger !== currentValue) setIsCustomTrigger(currentValue);
    }, [children, isCustomTrigger, setIsCustomTrigger]);

    return (
      <Select {...props} state={select}>
        {(selectProps) =>
          children ? (
            mergePropsIntoSingleChild(
              { ...selectProps, children: labelChildren },
              children
            )
          ) : (
            <SelectTrigger {...selectProps}>{labelChildren}</SelectTrigger>
          )
        }
      </Select>
    );
  },
  { treeName: "Trigger" }
);

// item
// ----

/** An option in a select. Must be a child of `<Select.Content />`.
 *
 * @example
 * <Select.Option value="first-option">First option</Select.Option>
 */
export const Option = createComponent<AtlasSelectOptionComponent>(
  (props) => {
    const { select } = useRootContext();
    const isSelected = isOptionSelected(select.value, props.value);
    return (
      <SelectItem
        // add the label property which is accessed by the trigger
        getItem={useCallback<NonNullable<SelectItemProps["getItem"]>>(
          (item) => ({ ...item, label: props.children }),
          [props.children]
        )}
        {...getOptionProps({ ...props, isSelected })}
      />
    );
  },
  { treeName: "Option", metadata: { collectionType: "option" } }
);

// separator
// ---------

/** A separator in a select. Must be a child of `<Select.Content />`.
 *
 * @example
 * <Select.Separator />
 */
export const Separator = createComponent<AtlasSelectSeparatorComponent>(
  (props) => (
    <SelectSeparator {...props}>
      {(separatorProps) => <OptionSeparator {...separatorProps} />}
    </SelectSeparator>
  ),
  { treeName: "Separator", metadata: { collectionType: "separator" } }
);

// content
// -------

// virtual composite list options

const COMPOSITE_ITEM_TYPES: AtlasSelectContentItem["_type"][] = ["option"];

const OPTION_SEPARATOR_SIZE = 17;
const OPTION_ITEM_SIZE: Record<
  NonNullable<AtlasOptionItemProps["size"]>,
  number
> = { compact: 32, default: 40, open: 44 };

function estimateItemSize(item: AtlasSelectContentItem) {
  /* eslint-disable no-underscore-dangle */
  if (item._type === "separator") return OPTION_SEPARATOR_SIZE;
  if (item._type === "option") return OPTION_ITEM_SIZE[item.size ?? "default"];
  /* eslint-enable no-underscore-dangle */
  throw new Error("Unknown item type");
}

function isDynamicallySizedItem(item: AtlasSelectContentItem) {
  /* eslint-disable no-underscore-dangle */
  if (item._type === "separator") return false;
  if (item._type === "option") return !(item.render || item.renderContent);
  /* eslint-enable no-underscore-dangle */
  throw new Error("Unknown item type");
}

const BASE_VIRTUAL_COMPOSITE_OPTIONS = {
  compositeItemTypes: COMPOSITE_ITEM_TYPES,
  estimateSize: estimateItemSize,
  isDynamicallySized: isDynamicallySizedItem,
};

// list (menu list)

type ListProps = BaseListProps & { select: SelectState };

function List({ items, isVirtual, select }: ListProps) {
  const renderers: CollectionItemRenderers<AtlasSelectContentItem> = useMemo(
    () => ({
      separator: (props) => <Separator {...props} />,
      option: (props) => <Option {...props} />,
    }),
    []
  );

  // pin selected items when virtualizing the list
  const getCustomPinnedIndexes: GetCustomPinnedIndexes = useCallback(
    (idToIndex) => {
      const indexes: number[] = [];
      if (Array.isArray(select.value))
        select.items.forEach(({ value, id }) => {
          if (value && select.value.includes(value) && id)
            indexes.push(idToIndex(id));
        });
      else {
        const selectedItem = select.items.find(
          ({ value }) => select.value === value
        );
        if (selectedItem && selectedItem.id)
          indexes.push(idToIndex(selectedItem.id));
      }
      return indexes;
    },
    [select.items, select.value]
  );

  return (
    <CompositeListRenderer
      className={el`list`}
      items={items}
      renderers={renderers}
      isVirtual={isVirtual}
      virtualCompositeOptions={{
        compositeState: select,
        getCustomPinnedIndexes,
        ...BASE_VIRTUAL_COMPOSITE_OPTIONS,
      }}
    />
  );
}

// content

const DEFAULT_CONTENT_PROPS = createDefaultProps<AtlasSelectContentProps>()({
  emptyText: "No option",
  noResultsText: "No results",
} as const);

/** The content of a select. Must be a child of `<Select.Root />`. Receives its content
 * through the `items` prop (dynamic collection) or as children (static collection).
 *
 * @example
 * <Select.Content items={items}>
 */
export const Content = createComponent<AtlasSelectContentComponent>(
  (p0) => {
    const { select: originalSelect, isCustomTrigger } = useRootContext();

    const [select, p1] = usePExtendedState(p0, originalSelect);
    const [items, p2] = usePCollection(p1, "useSelectItems");
    const [{ searchableHeader, searchableList, isSearchableNoResults }, p3] =
      useSearchableSelectContent(p2, {
        select,
        items,
        virtualCompositeOptions: BASE_VIRTUAL_COMPOSITE_OPTIONS,
      });

    const {
      emptyText = DEFAULT_CONTENT_PROPS.emptyText,
      noResultsText = DEFAULT_CONTENT_PROPS.noResultsText,
      header,
      footer,
      isLoading,
      isVirtual,
      ...props
    } = p3;

    // fix focus-related scroll issues on browsers
    // TODO: fix in Ariakit directly
    useCompositeScrollWorkaround(select);

    // header
    const resolvedHeader = searchableHeader ?? header;

    // list
    const resolvedList = searchableList ?? (
      <List items={items} isVirtual={isVirtual} select={select} />
    );

    // empty state
    const isEmpty = isSearchableNoResults || items.length === 0;
    const resolvedEmptyText = isSearchableNoResults ? noResultsText : emptyText;

    return (
      <SelectPopover
        data-placement={select.currentPlacement}
        state={select}
        // if searchable, the composite in use is the combobox, so disable the menu composite
        composite={!select.searchable}
        {...props}
        className={clsx(el`content`, props.className)}
        {...scrollWorkaroundCompositeProps}
      >
        {(selectProps) => (
          <FloatingCard
            {...selectProps}
            sameWidth={!isCustomTrigger}
            header={resolvedHeader}
            bodyProps={{ className: el`content-body`, role: "presentation" }}
            footer={footer}
            isLoading={isLoading}
            isEmpty={isEmpty}
            emptyText={resolvedEmptyText}
          >
            {resolvedList}
          </FloatingCard>
        )}
      </SelectPopover>
    );
  },
  { treeName: "Content" }
);
