/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useQuery as useApolloQuery,
} from "@apollo/client";

type QueryType = DocumentNode;
type QueryOptions<
  TData = any,
  TVariables = OperationVariables
> = QueryHookOptions<TData, TVariables> & {
  throwOnError?: boolean;
};

export default function useQuery<TData = any, TVariables = OperationVariables>(
  query: QueryType,
  queryOptions?: QueryOptions<TData, TVariables>
) {
  const queryResult = useApolloQuery<TData, TVariables>(query, queryOptions);
  const throwOnError = queryOptions?.throwOnError ?? true;

  if (queryResult.error && throwOnError) {
    throw new ApolloError(queryResult.error);
  }

  return queryResult;
}
