import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPossibleNumber = (possiblePhone: string | null): boolean => {
  if (!possiblePhone) {
    return false;
  }

  if (possiblePhone.includes("http")) {
    return false;
  }

  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(possiblePhone, "US");

    return phoneUtil.isPossibleNumber(parsedPhone);
  } catch (_error) {
    return false;
  }
};

export const displayPhoneNumber = (phone: string): string => {
  const parsedPhone = phoneUtil.parseAndKeepRawInput(
    phone,
    phone.startsWith("+") ? undefined : "US"
  );

  return phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL);
};
