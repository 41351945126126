/* eslint-disable import/prefer-default-export */

type PropsWithDefaults<D, P, M = D | P> = {
  [K in keyof D | keyof P]: K extends keyof M
    ? K extends keyof D
      ? Exclude<M[K], undefined>
      : M[K]
    : K extends keyof P
    ? P[K]
    : K extends keyof D
    ? D[K]
    : never;
};

/** Applies a set of default values to a props object.
 * @deprecated Use `createDefaultProps` and prop destructuring instead.
 */
export function getPropsWithDefaults<P, D>(
  props: P,
  defaults: D
): PropsWithDefaults<D, P> {
  const propsCopy = { ...props };
  Object.entries(props).forEach(([_key, value]) => {
    const key = _key as keyof typeof props;
    if (value === undefined) delete propsCopy[key];
  });
  return { ...defaults, ...propsCopy } as unknown as PropsWithDefaults<D, P>;
}
