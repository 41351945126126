import { useTextField } from "@react-aria/textfield";
import { mergeProps } from "@react-aria/utils";
import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  useRef,
} from "react";
import mergeRefs from "react-merge-refs";

import { getPropsWithDefaults, useOptionalRef } from "../__utils/__deprecated";
import TextFieldBase from "./TextFieldBase";
import { AtlasTextFieldProps } from "./types";

// config
// ------

const DEFAULT_PROPS = {
  size: "medium",
  required: false,
  isClearable: false,
} as const;

// component
// ---------

function TextFieldComponent(
  { inputRef: inputRefProp, ...props }: AtlasTextFieldProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = getPropsWithDefaults(props, DEFAULT_PROPS);
  const domRef = useOptionalRef(ref);
  const inputRef = mergeRefs<HTMLInputElement>([
    useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>,
    // I hate ref types and I hate my life and I'll refactor later leave me alone
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRefProp as any,
  ]);

  // behavior
  // --------

  const { labelProps, inputProps } = useTextField(
    {
      ...p,
      // TODO: there must be a better way...
      type: p.type === "button" ? "text" : p.type, // fix tooltip
    },
    // I hate ref types and I hate my life and I'll refactor later leave me alone
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef as any
  );
  const mergedInputProps = mergeProps(inputProps, p.UNSAFE_inputProps ?? {});

  return (
    <TextFieldBase
      ref={domRef}
      {...p} // pass properties down to base element
      labelProps={labelProps}
      inputProps={mergedInputProps}
      inputRef={inputRef}
    />
  );
}

/**
 * A text input.
 */
const TextField = forwardRef<HTMLDivElement, AtlasTextFieldProps>(
  TextFieldComponent
);

export default TextField;
