const PREFIX = "atlas-";
const ELEMENT_SEPARATOR = "__";

/** @deprecated Use createComponentUtils instead. */
export function makeRootClassName(name: string): string {
  return `${PREFIX}${name}`;
}

/** @deprecated Use createComponentUtils instead. */
export function makeElementClassNameFactory(rootClassName: string) {
  function makeElementClassName(name: string): string;
  function makeElementClassName(strings: TemplateStringsArray): string;
  function makeElementClassName(input: string | TemplateStringsArray): string {
    const name = typeof input === "string" ? input : input[0];
    return `${rootClassName}${ELEMENT_SEPARATOR}${name}`;
  }

  return makeElementClassName;
}
