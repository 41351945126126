import type { PartialBy } from "../../../__utils/types";
import type {
  ValueSet,
  VariableConfigAnnotationRenderer,
  VariableConfigUIRenderer,
  VariableNameSet,
  VariableRenderer,
  VariableRendererSet,
  VariableSet as _VariableSet,
  VariableSetEntry,
  VariableSpec,
  VariableSpecSet,
} from "./types";

// sets
// ----

export function createRendererSet<VariableSet extends _VariableSet>(
  rendererSet: VariableRendererSet<VariableSet>
) {
  return rendererSet;
}

export function createNameSet<VariableSet extends _VariableSet>(
  nameSet: VariableNameSet<VariableSet>
) {
  return nameSet;
}

export type PartialVariableSpec<
  SetEntry extends VariableSetEntry<unknown, unknown>
> = PartialBy<
  VariableSpec<SetEntry["value"], SetEntry["config"]>,
  "renderVariable" | "name" | "label"
>;
type PartialVariableSpecSet<VariableSet extends _VariableSet> = {
  [K in keyof VariableSpecSet<VariableSet>]: PartialVariableSpec<
    VariableSet[K]
  >;
};

export function createSpecSet<VariableSet extends _VariableSet>(
  partialSpecSet: PartialVariableSpecSet<VariableSet>,
  {
    rendererSet,
    nameSet,
  }: {
    rendererSet: VariableRendererSet<VariableSet>;
    nameSet: VariableNameSet<VariableSet>;
  }
) {
  // @ts-expect-error It is populated below.
  const specSet: VariableSpecSet<VariableSet> = {};

  Object.entries(partialSpecSet).forEach(([id, spec]) => {
    if (!rendererSet[id] && !spec.renderVariable)
      throw new Error(`Missing variable renderer for id '${id}'`);
    const { name, label } = nameSet[id];
    specSet[id as keyof VariableSet] = {
      ...spec,
      renderVariable: rendererSet[id] ?? spec.renderVariable,
      name: name ?? spec.name,
      label: label ?? spec.label,
    };
  });

  return specSet;
}

export function createValueSet<VariableSet extends _VariableSet>(
  valueSet: ValueSet<VariableSet>
) {
  return valueSet;
}

// renderers
// ---------

export function createVariableRenderer<
  SetEntry extends VariableSetEntry<unknown, unknown>
>(renderVariable: VariableRenderer<SetEntry["value"], SetEntry["config"]>) {
  return renderVariable;
}

export function createVariableConfigUIRenderer<
  SetEntry extends VariableSetEntry<unknown, unknown>
>(
  renderConfigUI: VariableConfigUIRenderer<
    SetEntry["value"],
    SetEntry["config"]
  >
) {
  return renderConfigUI;
}

export function createVariableConfigAnnotationRenderer<
  SetEntry extends VariableSetEntry<unknown, unknown>
>(
  renderConfigUI: VariableConfigAnnotationRenderer<
    SetEntry["value"],
    SetEntry["config"]
  >
) {
  return renderConfigUI;
}

// spec
// ----

export function createVariableSpec<
  SetEntry extends VariableSetEntry<unknown, unknown>
>(spec: PartialVariableSpec<SetEntry>) {
  return spec;
}
