import "./premade-variables.sass";

import { displayPhoneNumber } from "@resource/common";
import { useEffect, useState } from "react";

import { createComponentUtils } from "../../../__utils/atlas";
import { Button } from "../../../button";
import { Select } from "../../../select";
import { TextField } from "../../../textfield";
import { theme } from "../../theme";
import type { VariableSetEntry } from "./types";
import {
  createVariableConfigAnnotationRenderer,
  createVariableConfigUIRenderer,
  createVariableRenderer,
  createVariableSpec,
  PartialVariableSpec,
} from "./variable-sets";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Variable_premade-variables";

const { el } = createComponentUtils(COMPONENT_NAME);

// customization
// -------------

type CustomizeSpec<SetEntry extends VariableSetEntry<unknown, unknown>> = (
  current: PartialVariableSpec<SetEntry>
) => Partial<PartialVariableSpec<SetEntry>>;

function withCustomization<SetEntry extends VariableSetEntry<unknown, unknown>>(
  spec: PartialVariableSpec<SetEntry>,
  customize?: CustomizeSpec<SetEntry>
) {
  if (!customize) return spec;
  const customizations = customize(spec);
  return { ...spec, ...customizations };
}

// text
// ----

export type Text = { value: string };

export const renderText = createVariableRenderer<Text>(({ value }) => value);

export function text(customize?: CustomizeSpec<Text>) {
  const spec = createVariableSpec<Text>({ renderVariable: renderText });
  return withCustomization(spec, customize);
}

// phone
// ----

export type Phone = { value: string };

export const renderPhone = createVariableRenderer<Phone>(({ value }) =>
  displayPhoneNumber(value)
);

export function phone(customize?: CustomizeSpec<Phone>) {
  const spec = createVariableSpec<Phone>({ renderVariable: renderText });
  return withCustomization(spec, customize);
}

// person name
// -----------

export type PersonName = {
  value: { first: string; last: string };
  config: { display: "full-name" | "first-name" | "last-name" };
};

const defaultPersonNameConfig = { display: "full-name" } as const;

export const renderPersonName = createVariableRenderer<PersonName>(
  ({ value: { first, last }, config }) => {
    if (config.display === "first-name") return first;
    if (config.display === "last-name") return last;
    if (config.display === "full-name") return `${first} ${last}`;
    throw new Error("Invalid variable config");
  }
);

const renderPersonNameConfigUI = createVariableConfigUIRenderer<PersonName>(
  ({ config, updateConfig, value, renderVariable }) => {
    function renderOptionLabel(name: string, display: typeof config.display) {
      let label = name;
      if (value) label += ` ("${renderVariable({ display })}")`;
      return label;
    }

    return {
      body: (
        <div className={el`person-name`}>
          <p className="display-as">Display as</p>
          <Select.Root
            value={config.display}
            setValue={(newValue) =>
              updateConfig({ display: newValue as typeof config.display })
            }
          >
            {/* TODO: remove className once we fix the legacy styles issues in product */}
            <Select.Trigger size="small" className="bg-light-gray-500" />
            <Select.Content portal>
              <Select.Option value="full-name" size="compact">
                {renderOptionLabel("Full name", "full-name")}
              </Select.Option>
              <Select.Option value="first-name" size="compact">
                {renderOptionLabel("First name", "first-name")}
              </Select.Option>
              <Select.Option value="last-name" size="compact">
                {renderOptionLabel("Last name", "last-name")}
              </Select.Option>
            </Select.Content>
          </Select.Root>
        </div>
      ),
    };
  }
);

const renderPersonNameConfigAnnotation =
  createVariableConfigAnnotationRenderer<PersonName>(
    ({ config: { display } }) => {
      switch (display) {
        case "first-name":
          return "first";
        case "last-name":
          return "last";
        default:
          return undefined;
      }
    }
  );

export function personName(customize?: CustomizeSpec<PersonName>) {
  const spec = createVariableSpec<PersonName>({
    renderVariable: renderPersonName,
    renderConfigUI: renderPersonNameConfigUI,
    renderConfigAnnotation: renderPersonNameConfigAnnotation,
    defaultConfig: defaultPersonNameConfig,
    presets: [
      { label: "First name", defaultConfig: { display: "first-name" } },
      { label: "Last name", defaultConfig: { display: "last-name" } },
    ],
  });
  return withCustomization(spec, customize);
}

// person names

export type PersonNames = {
  value: { firstNames: string; fullNames: string };
  config: { display: "full-names" | "first-names" };
};

const defaultPersonNamesConfig = { display: "full-names" } as const;

export const renderPersonNames = createVariableRenderer<PersonNames>(
  ({ value, config }) => {
    if (config.display === "first-names") return value.firstNames;
    if (config.display === "full-names") return value.fullNames;
    throw new Error("Invalid variable config");
  }
);

const renderPersonNamesConfigUI = createVariableConfigUIRenderer<PersonNames>(
  ({ config, updateConfig, value, renderVariable }) => {
    function renderOptionLabel(name: string, display: typeof config.display) {
      let label = name;
      if (value) label += ` ("${renderVariable({ display })}")`;
      return label;
    }

    return {
      body: (
        <div className={el`person-name`}>
          <p className="display-as">Display as</p>
          <Select.Root
            value={config.display}
            setValue={(newValue) =>
              updateConfig({ display: newValue as typeof config.display })
            }
          >
            {/* TODO: remove className once we fix the legacy styles issues in product */}
            <Select.Trigger size="small" className="bg-light-gray-500" />
            <Select.Content portal>
              <Select.Option value="full-names" size="compact">
                {renderOptionLabel("Full names", "full-names")}
              </Select.Option>
              <Select.Option value="first-names" size="compact">
                {renderOptionLabel("First names", "first-names")}
              </Select.Option>
            </Select.Content>
          </Select.Root>
        </div>
      ),
    };
  }
);

const renderPersonNamesConfigAnnotation =
  createVariableConfigAnnotationRenderer<PersonNames>(
    ({ config: { display } }) => {
      switch (display) {
        case "first-names":
          return "first";
        default:
          return undefined;
      }
    }
  );

export function personNames(customize?: CustomizeSpec<PersonNames>) {
  const spec = createVariableSpec<PersonNames>({
    renderVariable: renderPersonNames,
    renderConfigUI: renderPersonNamesConfigUI,
    renderConfigAnnotation: renderPersonNamesConfigAnnotation,
    defaultConfig: defaultPersonNamesConfig,
    presets: [
      { label: "First names", defaultConfig: { display: "first-names" } },
    ],
  });
  return withCustomization(spec, customize);
}

// link
// ----

export type Link = {
  value: string;
  config: { label?: string };
};

export const renderLink = createVariableRenderer<Link>(
  ({ value, config: { label } }, flags) =>
    // eslint-disable-next-line no-nested-ternary
    flags?.asText ? (
      label ? (
        `[${label}](${value})`
      ) : (
        `<${value}>`
      )
    ) : (
      <a
        onClick={(event) => event.preventDefault()}
        tabIndex={-1}
        className={theme.link}
        href={value}
        target="_blank"
        rel="noreferrer"
      >
        {label ?? value}
      </a>
    )
);

const renderLinkConfigUI = createVariableConfigUIRenderer<Link>(
  ({ open, config, updateConfig }) => {
    const [inputValue, setInputValue] = useState(config.label ?? "");

    // reset input value when the config UI is closed
    useEffect(() => {
      if (!open) setInputValue(config.label ?? "");
    }, [open, config.label]);

    return {
      body: (
        <div className={el`link-body`}>
          <p className="label">Link label</p>
          <TextField size="small" value={inputValue} onChange={setInputValue} />
        </div>
      ),
      footer: (
        <div className={el`link-footer`}>
          <Button
            size="small"
            variant="primary"
            disabled={inputValue === (config.label ?? "")}
            onClick={() => updateConfig({ label: inputValue || undefined })}
          >
            Save
          </Button>
        </div>
      ),
    };
  }
);

export function link(customize?: CustomizeSpec<Link>) {
  const spec = createVariableSpec<Link>({
    renderVariable: renderLink,
    renderConfigUI: renderLinkConfigUI,
  });
  return withCustomization(spec, customize);
}

// date
// ----

export type Date = {
  value: number; // Unix timestamp
  config: { format?: string; showDate?: boolean; showTime?: boolean };
};
export const renderDate = createVariableRenderer<Date>(() => "23/12/2022");

// TODO
const renderDateConfigUI = createVariableConfigUIRenderer<Date>(() => ({
  body: <p>TODO: config next interview date</p>,
}));

export function date(customize?: CustomizeSpec<Date>) {
  const spec = createVariableSpec<Date>({
    renderVariable: renderDate,
    renderConfigUI: renderDateConfigUI,
  });
  return withCustomization(spec, customize);
}
