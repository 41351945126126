/* eslint-disable import/prefer-default-export */
import { EditorThemeClasses } from "lexical";

import { isNotUndefined } from "./is-not-undefined";

type ClassesObject = { [K in string]: string | ClassesObject };

function mergeClassesObjects(a: ClassesObject, b: ClassesObject) {
  const merged: ClassesObject = { ...a, ...b };

  Object.entries(a).forEach(([key, valueA]) => {
    const valueB = b[key];

    if (typeof valueA === "string" && typeof valueB === "string")
      merged[key] = `${valueA} ${valueB}`;
    else if (typeof valueA === "object" && typeof valueB === "object")
      merged[key] = mergeClassesObjects(valueA, valueB);
  });

  return merged;
}

export function mergeThemes(themes?: (EditorThemeClasses | undefined)[]) {
  if (!themes) return undefined;
  return themes
    .filter(isNotUndefined)
    .reduce((acc, theme) => mergeClassesObjects(acc, theme), {});
}
