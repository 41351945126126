/* eslint-disable import/prefer-default-export */
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxListProps,
  ComboboxSeparator,
  ComboboxState,
  SelectItem,
  SelectItemProps,
} from "ariakit";
import match from "autosuggest-highlight/match";
import { useCallback, useMemo } from "react";
import { useDebouncedValue, useDidUpdate } from "rooks";

import { atlasSearch } from "../../../icons";
import { scrollWorkaroundCompositeProps } from "../../__utils/__deprecated";
import type { CollectionItemRenderers } from "../../__utils/collections";
import {
  CompositeListRenderer,
  VirtualCompositeOptions,
} from "../../__utils/CompositeListRenderer";
import { Icon } from "../../icon";
import { OptionSeparator } from "../../option";
import type {
  AtlasSelectContentItem,
  AtlasSelectContentProps,
  AtlasSelectOptionComponent,
  AtlasSelectSeparatorComponent,
  AtlasSelectState,
} from "../types";
import { getOptionProps } from "./option";
import {
  BaseListProps,
  createComponent,
  el,
  isOptionSelected,
  useRootContext,
} from "./shared";

// config
// ------

const DEFAULT_SEARCH_DEBOUNCE_WAIT = 500;
const TMP_COMBOBOX_INPUT_ICON = atlasSearch;

// combobox item renderer
// ----------------------

const ComboboxItemRenderer = createComponent<AtlasSelectOptionComponent>(
  (props) => {
    const { select } = useRootContext();
    const isSelected = isOptionSelected(select.value, props.value);
    const { children: renderOption, ...optionProps } = getOptionProps({
      ...props,
      isSelected,
    });

    const getItem = useCallback<NonNullable<SelectItemProps["getItem"]>>(
      (item) => ({ ...item, label: props.children }),
      [props.children]
    );

    return (
      <ComboboxItem
        // add the label property which is accessed by the trigger
        getItem={getItem}
        focusOnHover
        setValueOnClick={false}
        hideOnClick={false}
        {...optionProps}
      >
        {(itemProps) => (
          <SelectItem
            // gotta add it to both because of some ariakit weirdness
            getItem={getItem}
            {...itemProps}
            value={props.value}
          >
            {renderOption}
          </SelectItem>
        )}
      </ComboboxItem>
    );
  },
  { treeName: "Option" }
);

// combobox separator renderer
// ---------------------------

const ComboboxSeparatorRenderer =
  createComponent<AtlasSelectSeparatorComponent>(
    (props) => (
      <ComboboxSeparator {...props}>
        {(separatorProps) => <OptionSeparator {...separatorProps} />}
      </ComboboxSeparator>
    ),
    { treeName: "Separator" }
  );

// search list (combobox list)
// ---------------------------

type SearchListProps = BaseListProps & {
  combobox: ComboboxState;
  comboboxListProps?: Partial<ComboboxListProps>;
  virtualCompositeOptions: Omit<
    VirtualCompositeOptions<AtlasSelectContentItem>,
    "compositeState"
  >;
};

function SearchList({
  items,
  isVirtual,
  combobox,
  comboboxListProps,
  virtualCompositeOptions,
}: SearchListProps) {
  const renderers: CollectionItemRenderers<AtlasSelectContentItem> = useMemo(
    () => ({
      separator: (props) => <ComboboxSeparatorRenderer {...props} />,
      option: (props) => <ComboboxItemRenderer {...props} />,
    }),
    []
  );

  return (
    <ComboboxList
      className={el`combobox-list`}
      state={combobox}
      {...comboboxListProps}
    >
      <CompositeListRenderer
        className={el`list`}
        items={items}
        renderers={renderers}
        virtualCompositeOptions={{
          compositeState: combobox,
          ...virtualCompositeOptions,
        }}
        isVirtual={isVirtual}
      />
    </ComboboxList>
  );
}

// filter
// ------

function filterItems(
  value: string,
  items: AtlasSelectContentItem[]
): AtlasSelectContentItem[] {
  const result: AtlasSelectContentItem[] = [];

  items.forEach((item) => {
    // eslint-disable-next-line no-underscore-dangle
    if (item._type !== "option") return;
    const matches = match(item.children, value, { requireMatchAll: true });
    if (matches.length > 0) result.push({ ...item, highlights: matches });
  });

  return result;
}

// searchable select content
// -------------------------

type UseSearchableSelectContentOptions = {
  select: AtlasSelectState;
  items: AtlasSelectContentItem[];
  virtualCompositeOptions: Omit<
    VirtualCompositeOptions<AtlasSelectContentItem>,
    "compositeState"
  >;
  header?: React.ReactNode;
};

export function useSearchableSelectContent(
  p0: AtlasSelectContentProps,
  { select, items, virtualCompositeOptions }: UseSearchableSelectContentOptions
) {
  // extract props
  const {
    searchDebounceWait = DEFAULT_SEARCH_DEBOUNCE_WAIT,
    isInstantSearch,
    isFastSelection,
    comboboxProps,
    comboboxListProps,
    ...props
  } = p0;

  const { isVirtual } = props;
  const { combobox } = select;

  // reset combobox value when select is closed
  if (select.searchable && !select.mounted && combobox.value)
    combobox.setValue("");

  // optionally debounce the search value
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebouncedValue(
    combobox.value,
    searchDebounceWait
  );

  // reset debounced value when the combobox is empty
  if (select.searchable && !combobox.value && debouncedSearchValue)
    setDebouncedSearchValue("");

  // resolve search value
  const resolvedSearchValue = useMemo(
    () =>
      isInstantSearch || combobox.value === ""
        ? combobox.value
        : debouncedSearchValue,
    [combobox.value, debouncedSearchValue, isInstantSearch]
  );

  // filter items with search value
  const filteredItems = useMemo(
    () =>
      select.searchable && resolvedSearchValue
        ? filterItems(resolvedSearchValue, items)
        : undefined,
    [select.searchable, resolvedSearchValue, items]
  );

  // fast selection: ensure there's (almost) always a selection
  useDidUpdate(() => {
    if (
      !select.searchable ||
      !isFastSelection ||
      !combobox.items.length ||
      combobox.activeId
    )
      return;
    combobox.move(combobox.first());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedSearchValue]);

  // create searchable select header
  const searchableHeader = select.searchable ? (
    // use a combobox in the header if it's searchable
    <Combobox
      state={combobox}
      placeholder="Search"
      autoSelect
      {...comboboxProps}
      {...scrollWorkaroundCompositeProps}
    >
      {(comboboxInputProps) => (
        // TODO: replace with <TextField /> once refactored
        <>
          {props.header}
          <div className={el`tmp-combobox-input`}>
            <Icon content={TMP_COMBOBOX_INPUT_ICON} />
            <input {...comboboxInputProps} />
          </div>
        </>
      )}
    </Combobox>
  ) : undefined;

  // render searchable list
  const searchableList = select.searchable ? (
    <SearchList
      items={filteredItems ?? items}
      isVirtual={isVirtual}
      combobox={combobox}
      comboboxListProps={comboboxListProps}
      virtualCompositeOptions={virtualCompositeOptions}
    />
  ) : undefined;

  const isSearchableNoResults =
    select.searchable && (filteredItems ?? items).length === 0;

  return [
    { searchableHeader, searchableList, isSearchableNoResults },
    props,
  ] as const;
}
